:global {
  * {
    padding: 0;
    margin: 0;
  }

  body {
    background-color: #fff;

    :global {
      .ant-layout {
        background-color: #fff;
      }
      .ant-layout-header {
        position: fixed;
        z-index: 9999;
        display: flex;
        justify-content: space-between;
        color: #fff;
        background-color: #ffffff;
        height: 58px;
        // padding: 0 50px;
        padding: 0 5%;
        width: 100%;
        line-height: 58px;
        border-bottom: 1px solid #f6f6f6;
        .ant-menu {
          transition: none;
          border-bottom: 1px solid transparent;
        }
        .ant-menu-horizontal {
          line-height: 57px;
        }
        .ant-menu-horizontal > .ant-menu-item {
          // border-bottom: 1px solid transparent;
          border-bottom: 1px solid #f6f6f6;
          top: 0;
        }
        .ant-menu-item-selected,
        .ant-menu-item-active {
          // color: #ffffff !important;
          background-color: #1890ff !important;
          > a,
          > a:hover {
            color: #fff;
          }
        }
        a {
          color: #000000;
        }
      }

      .ant-carousel .slick-dots {
        height: auto;
        bottom: 40px;
      }
      .slick-dots {
        li {
          margin: 0 12px !important;
          background-image: url(./statics/image/home/banner/rightarrow.png);
          button {
            height: 21px !important;
            width: 21px !important;
            background-color: transparent !important;
          }
        }

        .slick-active {
          background-image: url(./statics/image/home/banner/rightarrow-hover.png);
          button {
            background-color: transparent !important;
          }
        }
      }

      .ant-modal {
        width: 350px !important;
      }

      .ant-modal-content {
        border-radius: 0;

        .ant-modal-body {
          padding: 0;
        }
      }

      .ant-form-item-control {
        font-size: 14px;
        line-height: 12px;
      }

      .ant-form-explain {
        font-size: 14px;
      }

      .ant-layout-footer {
        padding: 52px 0 42px;
        height: 252px;
        background-color: rgb(41, 42, 42);
        color: #ffffff;
        .footer-wrap {
          display: flex;
          a,
          a:hover {
            color: #ffffff;
          }
          .qr-wrap {
            width: 276px;
            margin-right: 70px;
            display: flex;
            margin-left: 60px;
            .footer-wechat,
            .footer-sina {
              width: 120px;
              height: 155px;
              background-repeat: no-repeat;
              background-size: cover;
            }
            .footer-wechat {
              margin-right: 36px;
              background-image: url(./statics/image/home/footerwechat.png);
            }
            .footer-sina {
              background-image: url(./statics/image/home/footersina.png);
            }
          }
          i.footer-police,
          i.footer-phone {
            background-repeat: no-repeat;
            background-size: cover;
            display: inline-block;
          }
          i.footer-phone {
            background-image: url(./statics/image/home/footer_phone.png);
            width: 26px;
            height: 28px;
            margin-right: 14px;
            position: relative;
            top: 2px;
          }
          i.footer-police {
            background-image: url(./statics/image/home/footer_police.png);
            width: 15px;
            height: 15px;
            margin-right: 4px;
            position: relative;
            top: 2px;
          }
          .contact-wrap {
            width: 898px;
            color: #ffffff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            p {
              font-size: 20px;
              margin-bottom: 4px;
            }
          }
        }
      }
    }
  }
  /* stylelint-disable */
  @media screen and (max-width: 767px) {
    #root {
      /* position: relative;
      height: 100vh;
      letter-spacing: 0.5px;
      background: url(./statics/image/logo.png) center 30% no-repeat,
        linear-gradient(#666, #111);
      background-size: 224px;
      > * {
        display: none;
      }
      &::before,
      &::after {
        position: absolute;
        width: 100vw;
        text-align: center;
      }
      &::before {
        content: "本平台不支持移动端";
        top: 40vh;
        color: #fff;
        font-size: 24px;
        font-weight: 700;
      }
      &::after {
        content: "请使用 PC 端访问";
        top: 45vh;
        color: #cfcfcf;
        font-size: 16px;
      } */
    }
  }
}
